<template>
  <div class="ui_select" @click="switchClickTag" :style="{width:width}">
    <div class="val"><span v-html="val.name"></span></div>
    <ul v-show="clickTag">
      <li
        :class="{'on':val.val==item.val}"
        v-for="(item, inx) in list"
        :key="inx"
        v-html="item.name"
        @click="updateInputVal(item)"
        :value="item.val"
      ></li>
    </ul>
    <div
      class="ico"
      :style="{ transform: clickTag ? 'rotate(180deg)' : 'rotate(0)' }"
    >
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.34315 8.07107L0.979185 1.70711C0.58866 1.31658 0.58866 0.683417 0.979185 0.292893C1.36971 -0.0976315 2.00287 -0.0976318 2.3934 0.292893L7.34315 5.24264L12.2929 0.292893C12.6834 -0.097631 13.3166 -0.097631 13.7071 0.292893Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ui_select {
  color: #fff;
  cursor: pointer;
  padding: 0 0.1rem;
  width: 1.466rem;
  height: 0.48rem;
  line-height: 0.48rem;
  border: 1px solid #38366c;
  border-radius: 0.05rem;
  position: relative;
  font-size: 0.18rem;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .ico {
    position: absolute;
    right: 0.15rem;
    transition: all 0.5s;
  }
  ul {
    position: absolute;
    top: 0.45rem;
    left: 0;
    background-color: #2a2958;
    width: 100%;
    background: #2a2958;
    border: 1px solid #38366c;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    li {
      padding: 0.1rem;
      display: flex;
      align-items: center;
      margin: 0 !important;
      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
}
</style>
<script>
import { defineComponent, reactive, ref } from "vue";
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default defineComponent({
  name: "ui_select",
  props: {
    list: {
      type:Array,
      default(){ [
        {
          name: "item1",
          val: '1',
          default: true,
        },
        {
          name: "item2",
          val: '2',
          default: false,
        },
      ]},
    },
    width: {
      type: String,
      default: '1.47rem'
    }
  },
  components: {},
  emits: ["update:val"],
  setup(props, ctx) {
    // let router = useRouter();
    //   let store = useStore();
    let clickTag = ref(false);
    let val = reactive({
      name: "",
      val: "",
    });
    props.list.forEach((element) => {
      if (element.default) {
        val.name = element.name;
        val.val = element.val;
      }
    });
    const switchClickTag = () => {
      if (clickTag.value) {
        clickTag.value = false;
      } else {
        clickTag.value = true;
      }
    };
    const updateInputVal = (item) => {

      val.val = item.val
      val.name = item.name;
      ctx.emit("update:val", val.val);
    };
    return { val, clickTag, switchClickTag, updateInputVal };
  },
});
</script>
