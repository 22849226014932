<template>
  <div class="template btn_buy">
    <ui_btn_1
      @click="showBuyConfirm(props.data, props.type)"
      class="btn_buy"
      :width="'100%'"
      :height="'0.48rem'"
      >{{ lang == "en" ? "BUY NOW" : "購買" }}</ui_btn_1
    >
    <ui_confirm_2
      v-if="showInputNickUI"
      class="confirm"
      v-model="showInputNickUI"
      :title="langTxt[lang].center[25]"
      :canceltxt="lang == 'en' ? 'Cancel' : '取消'"
      :confirmtxt="lang == 'en' ? 'Ok' : '確定'"
      @confirm="buyModifyNickCard"
    >
      <!-- <p v-if="oldNickanme">{{ oldNickanme }}</p> -->
      <input v-model="newNickname" type="text" :placeholder="langTxt[lang].center[24]" />
    </ui_confirm_2>
  </div>
</template>
<script setup>
import { ref, computed, inject } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common";
import ui_btn_1 from "@/components/ui/ui_btn_1";
import ui_confirm_2 from "@/components/ui/ui_confirm_2.vue";
import axios from "axios";
let props = defineProps(["data", "type"]);
let store = useStore();
var lang = computed(() => {
  return store.state.lang;
});
var langTxt = store.state.text;
const formatAssetInfos = (asset_infos) => {
  return common.formatAssetInfos(asset_infos);
};

//是否顯示輸入暱稱UI
let showInputNickUI = ref(false);
//舊暱稱
let oldNickanme = computed(() => {
  if (store.state.userInfo && store.state.userInfo.nick) {
    return store.state.userInfo.nick;
  } else {
    return "";
  }
});
//新暱稱
let newNickname = ref("");
//修改呢稱，購買改名卡
const buyModifyNickCard = () => {
  //名字限定4-12.中文算兩字
  let newNickLength = common.chkHalf(newNickname.value);
  if (newNickLength < 2 || newNickLength > 6) {
    toastr["error"]("New nick must be larger than 2 and smaller than 6.");
    return;
  }
  const sendUrl = common.userApi.requestUrl + "/user_api/user_modify_nick_cards";
  const sendHeader = common.buildHeaders("form");
  const sendData = common.buildSendData({ nick: newNickname.value });
  common.showLoading("buyModifyNickCard");
  axios
    .put(sendUrl, sendData, { headers: sendHeader })
    .then((r) => {
      console.log("--lb", r);
      showInputNickUI.value = false;
      if (r.data.code == 0) {
        //更新用戶信息
        common.updateUserInfo();
        toastr["success"](r.data.msg);
      } else {
        console.error(r.data.msg);
        toastr["error"](r.data.msg);
      }
    })
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      common.hideLoading("buyModifyNickCard");
    });
};

//確認購買。r為查詢用戶財產的數據
const confirmBuy = (r, Item, type) => {
  console.log("--lb", Item, r.data);
  let sendUrl = common.userApi.requestUrl;
  // 如果是卡片類
  if (type == 1 && Item.value && Item.value.asset_infos) {
    let price = Item.value.asset_infos[0];
    let userAsset;
    for (let k in r.data.infos[0]) {
      let res = r.data.infos[0][k];
      for (let i = 0; i < res.length; i++) {
        if (res[i].type == price.type) {
          userAsset = res[i];
          break;
        }
      }
    }
    console.log("--lb", price, userAsset);
    //如果餘額不足
    if (userAsset.value < price.value) {
      toastr["error"]("Not enough gold");
      return;
    } else {
      //改名卡
      if (Item.name == "modify_nick_card_info") {
        //顯示新名字輸入框
        showInputNickUI.value = true;
      } else {
        // Chat卡(VIP) /user_api/user_vip_cards
        if (Item.name == "vip_card_info") {
          sendUrl += "/user_api/user_vip_cards";
        }
        ///exp charm 魅力 卡 finance_api/user_charm_cards
        else if (Item.name == "charm_card_info") {
          sendUrl += "/finance_api/user_charm_cards";
        }
        axios.put(sendUrl, {}, { headers: common.buildHeaders("form") }).then((r) => {
          if (r.data.code == 0) {
            common.updateUserInfo();
            toastr["success"](r.data.msg);
          } else {
            console.error(r.data.msg);
            toastr["error"](r.data.msg);
          }
        });
      }
    }
  }
  // 鋤頭
  else if (type == 1 && Item.award) {
    console.log(Item);
    //判断是否符合锄头对用户等级的要求
    // if(Item.user_level<=store.state.userInfo.level){
    const sendUrl = common.userApi.requestUrl + "/finance_api/silver_hoes";
    const sendData = common.buildSendData({ hlevel: Item.level });
    common.showLoading("buy silver_hoes");
    axios
      .post(sendUrl, sendData)
      .then((r) => {
        if (r.data.code == 0) {
          toastr["success"](r.data.msg);
        } else {
          console.error(r.data.msg);
          toastr["error"](r.data.msg);
        }
      })
      .finally(() => {
        common.hideLoading("buy silver_hoes");
      });
    // }
    // else{
    //     toastr['error']('The user level does not meet requirements');
    //     return;
    // }
  }
  // NFT
  else if (type == 0) {
    sendUrl += `/finance_api/block_chain/2/nft/prop/${props.data.nft_category}/${props.data.nft_level}`;
    axios
      .put(sendUrl, common.buildSendData({}), { headers: common.buildHeaders("form") })
      .then((r) => {
        if (r.data.code == 0) {
          toastr["success"](r.data.msg);
        } else {
          console.error(r.data.msg);
          toastr["error"](r.data.msg);
        }
      });
  }
  // 如果是其它類
  else if (type == 2) {
    const sendUrl = common.userApi.requestUrl + `/finance_api/user_goodses`;
    const sendData = common.buildSendData({ type: props.data.info.type });
    axios.put(sendUrl, sendData, { headers: common.buildHeaders("form") }).then((r) => {
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
      } else {
        console.error(r.data.msg);
        toastr["error"](r.data.msg);
      }
    });
  }
};
const showBuyConfirm = (Item, type) => {
  common.confirm({
    type: "info",
    title: lang.value == "en" ? "Tips" : "提示",
    text:
      lang.value == "en"
        ? "Are you sure to purchase this item?"
        : "您確定要購買此商品嗎？",
    btnTxt: lang.value == "en" ? "Confirm" : "確認",
    showCancleBtn: true,
    confirmFn: function (r) {
      // 獲取用戶餘額後判斷是否繼續購買流程
      common.queryFinance().then((r) => {
        confirmBuy(r, Item, type);
      });
    },
    cancelFn: function (r) {
      store.commit("set_showConfirmTag", false);
    },
  });
};
</script>
<style lang="scss" scoped>
.btn_buy {
  .btn_buy {
    border-radius: 0;
  }
  .box {
    width: 4.6rem;
    height: 6.4rem;
    margin: 0 auto;
    text-align: center;
    background: url("https://cdn.blockwar.io/web_assets/img/mall_item_bg_3.png") no-repeat
      center top;
    background-size: 4.6rem auto;
    border-radius: 0.1rem;

    .img {
      min-height: 2.4rem;
      margin-bottom: 0.35rem;
    }

    img {
      width: 3.03rem;
      margin-top: 0.48rem;
      height: auto;
    }

    h2,
    h3 {
      margin: 0;
      line-height: 0.42rem;
      font-size: 0.2rem;
      color: #ffffff80;
      margin-bottom: 0.2rem;
      display: flex;
      justify-content: center;
    }

    p {
      font-size: 0.14rem;
      color: #ffffff60;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      width: 4.2rem;
      text-transform: capitalize;
    }

    .v {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.2rem;
      color: #f2c94c;
      margin-bottom: 0.3rem;

      img {
        width: 0.16rem;
        height: auto;
        margin: 0;
        margin-right: 0.05rem;
      }
    }
    .desc {
      margin-bottom: 0.2rem;
      li {
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.14rem;
      }
    }
  }
  :deep(.confirm input) {
    padding: 0.13rem 0.2rem;
    font-size: 0.16rem;
    color: #4f4f4f;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 0.1rem;
    outline: none;
    &::-webkit-input-placeholder,
    &::placeholder {
      color: #e0e0e0;
    }
  }
}
</style>
