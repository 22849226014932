<template>
    <div class="ui_back">
        <span @click="$emit('updateVal')"><i></i>{{lang=='en'?'Back':'返回'}}</span>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
let store = useStore();
let lang = computed(() => {
    return store.state.lang;
});

</script>
<style lang="scss">
.ui_back {
    margin-bottom: 0.11rem;
    span {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 0.18rem;
    }
    i {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        background: url("https://cdn.blockwar.io/web_assets/img/ico_arrow_right.png") no-repeat center center / 0.24rem auto;
        transform: rotate(180deg);
        margin-right: 0.05rem;
    }
}
</style>