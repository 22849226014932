<template>
  <div class="ui_confirm disselect" v-if="props.modelValue">
    <div class="contain">
      <div class="title">
        {{ props.title }}
      </div>
      <div class="txt" id="confirm_txt">
        <slot />
      </div>
      <div class="btn">
        <span v-if="props.canceltxt" @click="close('cancel')">{{ props.canceltxt }}</span>
        <span v-if="props.confirmtxt" @click="close('confirm')" class="confirm">{{
          props.confirmtxt
        }}</span>
      </div>
      <span class="close" @click="$emit('update:modelValue', false)"></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ui_confirm {
  position: fixed;
  z-index: 8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .contain {
    width: 6.3rem;
    min-height: 2.27rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.3rem 0;
    position: relative;

    .title {
      font-weight: bold;
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      color: #333;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      background: #ffffff !important;
      padding: 0 0.3rem;
      padding-bottom: 0.1rem;
      margin-bottom: 0.2rem;

      i {
        margin-right: 0.1rem;

        svg {
          widows: 0.27rem;
          height: 0.27rem;
          vertical-align: middle;
        }
      }
    }

    .txt {
      font-size: 0.18rem;
      color: #5c5c5c;
      font-weight: 500;
      line-height: 0.25rem;
      padding: 0 0.3rem;
      min-height: 0.76rem;
      margin-bottom: 0.17rem;
      text-transform: capitalize;
      position: relative;
    }

    .btn {
      padding: 0 0.3rem;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        display: inline-block;
        width: 0.86rem;
        height: 0.38rem;
        background-color: #bdbdbd;
        border-radius: 5px;
        font-size: 0.18rem;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #828282;
        }

        &.confirm {
          background: var(--linearGradientRed);
          margin-left: 0.2rem;
          color: #fff;

          &:hover {
            background: var(--linearGradientRedHover);
          }
        }
      }
    }
  }
}

.close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
  background: url("https://cdn.blockwar.io/web_assets/img/ico_close.png") no-repeat center
    center / 100% auto;

  &:hover {
    background-image: url("https://cdn.blockwar.io/web_assets/img/ico_close_hover.png");
  }
}
</style>
<script setup>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
let props = defineProps(["modelValue", "title", "canceltxt", "confirmtxt"]);
let emits = defineEmits(["cancel", "confirm", "update:modelValue"]);
const close = (fnName) => {
  console.log(fnName);
  if (fnName) {
    emits(fnName);
  }
  emits("update:modelValue", false);
};
</script>
