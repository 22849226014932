<template>
  <div id="calender">
    <div class="txt-c p-10">
      <span @click="prev"> ⬅️ </span>
      <input type="text" v-model="year" />年
      <input type="text" v-model="month" class="month" />月
      <span @click="next"> ➡️️️️ </span>
    </div>

    <div class="weekDay flex jc-sb p-5 day">
      <p v-for="item in weekList" :key="item.id">{{ item }}</p>
    </div>

    <div class="weekDay flex p-5 day">
      <p v-for="item in spaceDay" :key="item.id"></p>

      <p
        v-for="(item, idx) in monthDay[this.month - 1] || 30"
        @click="setDay(idx)"
        :class="idx == activeDay ? 'active' : ''"
        :key="item.id"
      >
        {{ item }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "calender",
  data() {
    return {
      year: "", // 年份
      month: "", // 月份
      day: "", // 天数
      current: "", // 当前时间
      weekList: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      monthDay: [31, "", 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      activeDay: "", // 选中的日期
      spaceDay: "", // 每个月第一天是星期几
      February: "", // 判断2月份的天数
    };
  },
  created() {
    this.current = new Date();
    this.getTheCurrentDate();
    this.getMonthFisrtDay();
    this.February = this.isLeapYear(this.year) ? 28 : 29;
    this.monthDay.splice(1, 1, this.February);
  },
  watch: {
    month() {
      if (this.month > 12 || this.month < 1) {
        console.log('--lb',"请输入正确月份");
        return;
      }
      this.getMonthFisrtDay();
    },
    year() {
      this.getMonthFisrtDay();
    },
  },
  methods: {
    // 判断是否是闰年
    isLeapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    }, // 选取特定天数
    setDay(idx) {
      this.activeDay = idx;
      this.day = idx + 1;
      console.log('--lb',
        "选择的日期是" + this.year + " " + this.month + " " + this.day
      );
    }, // 判断月份的第一天是星期几
    getMonthFisrtDay() {
      var firstDayOfCurrentMonth = new Date(this.year, this.month - 1, 1); // 某年某月的第一天
      if (firstDayOfCurrentMonth.getDay() == 0) {
        this.spaceDay = 6;
      } else {
        this.spaceDay = firstDayOfCurrentMonth.getDay() - 1;
      }
    }, // 获取当前的日期
    getTheCurrentDate() {
      this.year = this.current.getFullYear();
      this.month = this.current.getMonth() + 1;
      this.day = this.current.getDate();
    },
    prev() {
      if (this.month == 1) {
        this.year--;
        this.month = 12;
      } else {
        this.month--;
      }
      this.activeDay = 0;
      this.getMonthFisrtDay();
    },
    next() {
      if (this.month == 12) {
        this.year++;
        this.month = 1;
      } else {
        this.month++;
      }
      this.activeDay = 0;
      this.getMonthFisrtDay();
    },
  },
};
</script>

<style lang="scss" scoped>
#calender {
  .txt-c {
    text-align: center;
  }
  .p-10 {
    padding: 2rem;
  }
  .p-5 {
    padding: 1rem;
  }
  .flex {
    display: flex;
  }
  .jc-sb {
    justify-content: space-between;
  }
  input {
    width: 50px;
    &.month {
      width: 30px;
    }
  }
  .day {
    flex-wrap: wrap;
    p {
      width: 14.28%;
      /*flex: 0 0 0 ;*/
      text-align: center;
      line-height: 2.4rem;
      height: 2.4rem;
      position: relative;
      z-index: 100;
      &.active {
        color: #fff;
      }
      &.active:before {
        content: "";
        height: 2.5rem;
        width: 2.5rem;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        transform: translateX(50%);
        border-radius: 50%;
        background: #e97163;
        color: #fff;
      }
    }
  }
}
</style>