<template>
    <div class="template sell_use_detail">
        <Ui_back class="back"  @updateVal="$emit('update:modelValue', null)" />
        <div class="content">
            <div class="main">
                <img v-if="props.modelValue.itemType=='NFT'" :src="props.modelValue.logo_url" alt="">
                <img v-else-if="props.modelValue.itemType=='Card'" :src="props.modelValue.logo" alt="">
                <img v-else-if="props.modelValue.itemType =='goods'" :src="props.modelValue.logo_url" alt="">
                <img v-else-if="props.modelValue.itemType =='HOES'" :src="props.modelValue.info.logo" alt="">
                <p class="name">
                    <span v-if="props.modelValue.itemType=='NFT'">{{ props.modelValue.name}}</span>
                    <span v-if="props.modelValue.itemType=='Card'">{{ props.modelValue.name[lang]}}</span>
                    <span v-if="props.modelValue.itemType =='goods'">{{ props.modelValue.name}}</span>
                    <span v-if="props.modelValue.itemType =='HOES'">{{ props.modelValue.info.name}}</span>
                </p>
                <Star_level class="level" v-model="starLevel" v-if="starLevel" />
                <ul class="desc" v-if="props.modelValue.itemType == 'Card'">
                    <li><span class="label">{{ lang == 'en' ?
                    "Durability":"耐久度"}}:&emsp;</span><span>{{props.modelValue.durability}} / {{ props.modelValue.max_durability }}</span></li>
                </ul>
                <div class="name" v-if="props.modelValue.info && props.modelValue.info.award">
                    <span class="label">{{ lang == 'en' ?
                        "Today used count":"今日使用次数"}}:&emsp;</span><span>{{ props.modelValue.today_used_count }}</span>
                </div>
                <div class="name" v-if="props.modelValue.info && props.modelValue.info.award">
                    <span class="label">{{ lang == 'en' ?
                        "Total used count":"共计使用次数"}}:&emsp;</span><span>{{ props.modelValue.total_used_count}}</span>
                </div>
                <div v-if="props.modelValue.price" class="price" v-html="formatAssetInfos(props.modelValue.price)"></div>
                <div class="btn">
                    <ui_btn_2 v-if="props.modelValue.itemType=='Card'" @click="showSellConfirm">{{lang=='en'?"SELL":"出售"}}</ui_btn_2>
                    <ui_btn_2 v-if="props.modelValue.itemType =='goods'" @click="showSellConfirm">{{lang=='en'?"USE":"使用"}}</ui_btn_2>
                    <ui_btn_2 v-if="props.modelValue.itemType =='HOES'" @click="showSellConfirm">{{lang=='en'?"USE":"使用"}}</ui_btn_2>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Ui_back from '@/components/ui/Ui_back.vue';
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import common from '../../utils/common';
import Star_level from '@/components/ui/Star_level.vue';
import ui_btn_2 from '@/components/ui/ui_btn_2'
import axios from 'axios';
let store = useStore();
let props = defineProps(['modelValue']);
let emits = defineEmits(['update:modelValue']);
console.log(props.modelValue)
let lang = computed(() => {
    return store.state.lang;
}); 
let starLevel = props.modelValue.level || props.modelValue.nft_level;
// 格式化商品的價格
const formatAssetInfos = (asset_infos) => {
    return common.formatAssetInfos(asset_infos);
}

// 顯示出售確認框
const showSellConfirm = () => {
    common.confirm({
        type: 'info',
        title: lang.value == 'en' ? 'Tips' : '提示',
        text: props.modelValue.itemType=='Card'?(lang.value == 'en' ? "Are you sure to sell this item?" : '您確定要售出此商品嗎？'):(lang.value == 'en' ? "Are you sure to use this item?" : '您確定要使用此商品嗎？'),
        btnTxt: lang.value == 'en' ? 'Confirm' : '確認',
        showCancleBtn: true,
        confirmFn: function (r) {
            if (props.modelValue.itemType == 'Card'){
                confirmSell(r);
            }
            else if (props.modelValue.itemType == 'goods') {
                confirmUse(r);
            }
            else if (props.modelValue.itemType == 'HOES') {
                confirmUseHoes(r);
            }
        },
        cancelFn: function (r) {
            store.commit("set_showConfirmTag", false);
        }

    });
}

// 調用祖先組件的方法，刷新數據
const getMybackpack = inject('getMybackpack');

//確認出售。r為查詢用戶財產的數據
const confirmSell = (r) => {
    let sendUrl = common.userApi.requestUrl + `/misc_api/selled_item_cards/${props.modelValue.game_id}/${props.modelValue.item_id}/${props.modelValue.level}`;
    let sendData = common.buildSendData({});
    axios.post(sendUrl, sendData, { headers: common.buildHeaders('form') }).then((r) => {
        if (r.data.code == 0) {
            toastr['success'](r.data.msg);
            emits('update:modelValue', null);
            //出售後，調用祖父組件的方法，刷新揹包數據
            getMybackpack();
            // emits('update:modelValue', null);
        }
        else {
            console.error(r.data.msg);
            toastr['error'](r.data.msg);
        }
    });
}
// 確認使用
const confirmUse = (r) => {
    let sendUrl = common.userApi.requestUrl + `/finance_api/user_used_goodses`;
    let sendData = common.buildSendData({ sn: props.modelValue.sn });
    axios.put(sendUrl, sendData, { headers: common.buildHeaders('form') }).then((r) => {
        if (r.data.code == 0) {
            toastr['success'](r.data.msg);
            emits('update:modelValue', null);
            //出售後，調用祖父組件的方法，刷新揹包數據
            getMybackpack();
            // emits('update:modelValue', null);
        }
        else {
            console.error(r.data.msg);
            toastr['error'](r.data.msg);
        }
    });
}
// 使用鋤頭
const confirmUseHoes = (r) => {
    let sendUrl = common.userApi.requestUrl + `/finance_api/silver_hoes/${props.modelValue.hlevel}/reward`;
    let sendData = common.buildSendData({});
    axios.put(sendUrl, sendData, { headers: common.buildHeaders('form') }).then((r)=>{
        if (r.data.code == 0) {
            toastr['success'](r.data.msg);
            emits('update:modelValue', null);
            //出售後，調用祖父組件的方法，刷新揹包數據
            getMybackpack();
        }
        else {
            console.error(r.data.msg);
            toastr['error'](r.data.msg);
        }
    });
}
</script>
<style lang="scss" scoped>
.back{
    margin-top: .2rem;
    margin-left: .3rem;
}
.content{
    min-height: 10rem;
    display: flex;
    justify-content: center;
    text-align: center;
    .main{
        width: 4.6rem;
        min-height: 6.40rem;
        background: url('/_static/img/mall_item_bg_3.ab0bf410.png') no-repeat center top;
        background-size: 100% auto;
        border-radius: 0.1rem;
        img{
            width: 3.03rem;
            margin-top: 0.48rem;
        }
        .name{
            height: 0.42rem;
            line-height: 0.42rem;
            font-size: 0.2rem;
            text-align: center;
            display: flex;
            width: 100%;
            justify-content: center;
        }
        .level{
            margin: 0.2rem 0;
        }
        .price{
            margin-bottom: 0.4rem;
            font-size: 0.20rem;

        }
        .desc {
            margin-bottom: 0.2rem;
        
            li {
                height: .4rem;
                line-height: 0.4rem;
                font-size: 0.14rem;
            }
        }
        .btn{
            display: flex;
            justify-content: center;
        }
    }
}
</style>