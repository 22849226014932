
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import common from "../../utils/common";
import ui_btn_1 from '@/components/ui/ui_btn_1';
import ui_select from "@/components/ui/ui_select";
import Detail from './detail.vue';
import Item from './Item.vue';
import axios from "axios";
import Btn_buy from './Btn_buy.vue';
export default defineComponent({
    name: "mall",
    props: {},
    components: { ui_btn_1, Detail, Item,ui_select,Btn_buy },
    setup(props, ctx) {
        let router = useRouter();
        let store = useStore();
        let lang = computed(() => {
            return store.state.lang
        });

        let tabIndex = ref(-1);
        // 背包商品的类型
        let typeList = [
            {
                name: "ALL",
                val: -1,
                default: true,
            },
            {
                name: "Shed",
                val: 0,
                default: false,
            },
            {
                name: "Card",
                val: 1,
                default: false,
            },
            {
                name: "Other",
                val: 2,
                default: false,
            },
        ];

        //選中的項目
        const currentItem = ref(null);

        // 格式化NFT商品的價格
        const formPrice = (tabIndex, item) => {
            if(tabIndex==0){
                return common.fortmatNFTprice(item);
            }
            else if (tabIndex == 1) {
                if(item.value && item.value.asset_infos){
                    return item.value.asset_infos;
                }
                else{
                    return item.price;
                }
            }
            else if (tabIndex == 2 && item.info) {
                return item.info.price;
            }
        }

        //格式化商品的圖
        const formatImgsrc = (tabIndex,item) => {
            if (tabIndex == 1) {
                if(item.logo){
                    return item.logo;
                }
                return item.name == 'modify_nick_card_info' ? '/_static/img/card_reanme.png' : item.name == 'vip_card_info' ? '/_static/img/card_vip.png' : '/_static/img/card_charm.png';
            }
            else if (tabIndex == 2 && item.info) {
                return item.info.logo_url;
            }
            else if (tabIndex == 0) {
                return item.logo_url
            }
        }

        //格式化商品名稱
        const formatItemName = (tabIndex, item) => {
            if (tabIndex == 0) {
                return item.name;
            }
            else if (tabIndex == 1) {
                if(item.show_name){
                    return item.show_name[lang.value];
                }
                if(item.life){
                    return 'Life: '+item.life/24/60/60+' Day';
                }
            }
            else if (tabIndex == 2 && item.info) {
                return item.info.name;
            }
            else{
                return '';
            }
        }

        //獲取系統默認ntf道具
        let ntfList = ref([]);
        const getNTFs = () => {
            const sendUrl = common.userApi.requestUrl + '/misc_api/sys_config/items?&names=nft_prop_infos';
            const sendHeader = common.buildHeaders('form');
            common.showLoading("mall getNTFs");
            currentItem.value = null;
            axios.get(sendUrl, { headers: sendHeader }).then((r) => {
                if (r.data.code == 0) {
                    const v = JSON.parse(r.data.items[0].value);
                    ntfList.value = v.infos;
                    // tabIndex.value = 0;
                    console.log('--lb', ntfList.value)
                }
                else {
                    if (r.data.code == 3) {
                        
                    }
                    console.error(r.data.msg);
                    toastr['error'](r.data.msg);
                }
            }).finally(() => {
                common.hideLoading("mall getNTFs");
            });
        };
        //獲取系統默認卡
        let cardsList = ref([]);
        const getCards = () => {
            const sendUrl = common.userApi.requestUrl + '/misc_api/sys_config/items?&names=modify_nick_card_info,vip_card_info,charm_card_info';
            const sendHeader = common.buildHeaders('form');
            common.showLoading("mall getCards");
            currentItem.value = null;
            axios.get(sendUrl, { headers: sendHeader }).then((r) => {
                if (r.data.items) {
                    for (let i = 0; i < r.data.items.length; i++) {
                        if (typeof r.data.items[i].value == "string") {
                            r.data.items[i].value = JSON.parse(r.data.items[i].value);
                        }
                        if (r.data.items[i].name == "modify_nick_card_info") {
                            r.data.items[i].show_name = { 'en': 'Modify Nick Card', 'zh_cn': '改名卡' };
                        }
                        else if (r.data.items[i].name == "vip_card_info") {
                            r.data.items[i].show_name = { 'en': 'Chat Card', 'zh_cn': '聊天卡' };
                        }
                        else if (r.data.items[i].name == "charm_card_info") {
                            r.data.items[i].show_name = { 'en': 'Charm Card', 'zh_cn': '魅力卡' };
                        }
                    }
                    cardsList.value = r.data.items;
                    // tabIndex.value = 1;
                }
                console.log('--lb', cardsList.value)
            }).finally(() => {
                common.hideLoading("mall getNTFs");
            });
            getSilverHoeList();
        };
        // 获取锄头
        let silverHoeList = ref([]);
        const getSilverHoeList = ()=>{
            const sendUrl = common.userApi.requestUrl + '/misc_api/sys_config/items?&names=silver_hoe_infos';
            const sendHeader = common.buildHeaders('form');
            common.showLoading("mall getSilverHoeList");
            axios.get(sendUrl, { headers: sendHeader }).then((r)=>{
                if(r.data.code==0){
                    silverHoeList.value = JSON.parse(r.data.items[0].value).infos;
                    console.log(silverHoeList.value);
                }
            }).finally(() => {
                common.hideLoading("mall getSilverHoeList");
            });;
        }
        // 獲取商品
        let goodsList = ref([]);
        const getGoodses = () => {
            const sendUrl = common.userApi.requestUrl + "/finance_api/goodses";
            const sendHeader = common.buildHeaders('form');
            common.showLoading("mall goodsList");
            currentItem.value = null;
            axios.get(sendUrl, { headers: sendHeader }).then((r) => {
                goodsList.value = r.data.infos;
                // tabIndex.value = 2;
                console.log('--lb', goodsList.value)
            }).finally(() => {
                common.hideLoading("mall goodsList");
            });
        };

        const setCurrentItem = (item,setTab)=>{
            // if(item.count && item.count==0){
            //     return;
            // }
            // currentItem.value = item;
            // currentItem.value.setTab = setTab;
        }

        onMounted(() => {
            getNTFs();
            getCards();
            getGoodses();
        });

        return { formatItemName, formatImgsrc, formPrice,tabIndex, currentItem, getGoodses, getCards, getNTFs, ntfList, cardsList, goodsList, lang,silverHoeList,typeList,setCurrentItem };
    },
});