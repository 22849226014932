<template>
    <div class="template convert_detail">
        <Ui_back class="back"  @updateVal="$emit('update:modelValue', null)" />
        <div class="content">
            <ul>
                <ConvertItemVue :item="modelValue">
                    <div class="btn">
                        <ui_btn_2 @click="$emit('update:modelValue', null)">{{lang=='en'?'CANCEL':'取消'}}</ui_btn_2>
                    </div>
                </ConvertItemVue>
            </ul>
            <div class="arrow">
                <div>
                    <svg width="92" height="109" viewBox="0 0 92 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.1818 54.3636L0 0H10.4545L37.6364 54.3636L12.5455 108.727H0L27.1818 54.3636Z"
                            fill="url(#paint0_linear_1927_23773)" />
                        <path d="M52.2723 54.3636L25.0905 0H35.5451L62.7269 54.3636L37.636 108.727H25.0905L52.2723 54.3636Z"
                            fill="url(#paint1_linear_1927_23773)" />
                        <path d="M81.5456 54.3636L54.3638 0H64.8183L92.0001 54.3636L66.9092 108.727H54.3638L81.5456 54.3636Z"
                            fill="url(#paint2_linear_1927_23773)" />
                        <defs>
                            <linearGradient id="paint0_linear_1927_23773" x1="18.8182" y1="0" x2="18.8182" y2="108.727"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#B3B4C8" />
                                <stop offset="0.503519" stop-color="white" />
                                <stop offset="1" stop-color="#B7B7CB" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_1927_23773" x1="43.9087" y1="0" x2="43.9087" y2="108.727"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#B3B4C8" />
                                <stop offset="0.503519" stop-color="white" />
                                <stop offset="1" stop-color="#B7B7CB" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_1927_23773" x1="73.182" y1="0" x2="73.182" y2="108.727"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#B3B4C8" />
                                <stop offset="0.503519" stop-color="white" />
                                <stop offset="1" stop-color="#B7B7CB" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div class="charge"><span>{{lang=='en'?'Exchange Charge':'手續費'}}:</span>&ensp; <i class="ico_asset ico_gold"></i> <span>{{ modelValue.nft_exchange_charge }}</span></div>
            </div>
            <div>
                <div class="to">
                    <div class="img"><img :src="props.modelValue.logo" alt=""></div>
                    <p class="name">
                        <span>{{ props.modelValue.name[lang]}}</span>
                    </p>
                    <ul class="desc">
                        <li v-for="(item, index) in convertMap[props.modelValue.item_id].desc" :key="index" :name="convertMap[props.modelValue.item_id].name"><span class="label">{{item.title}}</span><span class="value">{{item.value}}</span></li>
                        <li><span class="label">{{ lang == 'en' ?
                        "Durability":"耐久度"}}:&emsp;</span><span>{{props.modelValue.durability}} / {{ props.modelValue.max_durability
                        }}</span></li>
                    </ul>
                    <div class="scene">
                       Can be used on <span>{{convertMap[props.modelValue.item_id].scene}}</span>
                    </div>
                </div>
                    <div class="btn">
                        <ui_btn_2 :redback="true" @click="showConvertConfirm">{{ lang == 'en' ? "CONFIRM" :"開始轉換"}}</ui_btn_2>
                    </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Ui_back from '@/components/ui/Ui_back.vue';
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import common from '../../utils/common';
import Star_level from '@/components/ui/Star_level.vue';
import ConvertItemVue from './ConvertItem.vue';
import ui_btn_2 from '@/components/ui/ui_btn_2'
import axios from 'axios';
let store = useStore();
let props = defineProps(['modelValue']);
let emits = defineEmits(['update:modelValue']);
console.log(props.modelValue)
let lang = computed(() => {
    return store.state.lang;
}); 
let starLevel = props.modelValue.level || props.modelValue.nft_level;
// 格式化商品的價格
const formatAssetInfos = (asset_infos) => {
    return common.formatAssetInfos(asset_infos);
}

// 顯示出售確認框
const showConvertConfirm = () => {
    common.confirm({
        type: 'info',
        title: lang.value == 'en' ? 'Tips' : '提示',
        text: lang.value == 'en' ? "Are you sure to sell this item?" : '您確定要售出此商品嗎？',
        btnTxt: lang.value == 'en' ? 'Confirm' : '確認',
        showCancleBtn: true,
        confirmFn: function (r) {
            confirmConvert(r);
        },
        cancelFn: function (r) {
            store.commit("set_showConfirmTag", false);
        }

    });
}

// 轉換映射
const convertMapEN = [
    {}, {},
    {
        name: 'Grunt',
        scene: '伐木場',
        desc: [
            { title: '生產加速', value: '10%' },
            { title: '消耗降低', value: '20%' },
            { title: '衰老延遲', value: '10%' },
        ]
    },
    {
        name: 'Shaman',
        scene: '伐木場',
        desc: [
            { title: '生產加速', value: '20%' },
            { title: '消耗降低', value: '30%' },
            { title: '衰老延遲', value: '20%' },
        ]
    },
    {
        name: 'Tauren',
        scene: '伐木場',
        desc: [
            { title: '生產加速', value: '35%' },
            { title: '消耗降低', value: '10%' },
            { title: '衰老延遲', value: '15%' },
        ]
    },
    {
        name: 'Chief',
        scene: '礦場',
        desc: [
            { title: '生產加速', value: '35%' },
            { title: '消耗降低', value: '40%' },
            { title: '衰老延遲', value: '20%' },
        ]
    },
    {
        name: 'Warrior',
        scene: '農場',
        desc: [
            { title: '生產加速', value: '10%' },
            { title: '消耗降低', value: '20%' },
            { title: '衰老延遲', value: '10%' },
        ]
    },
    {
        name: 'Priest',
        scene: '礦場',
        desc: [
            { title: '生產加速', value: '30%' },
            { title: '消耗降低', value: '30%' },
            { title: '衰老延遲', value: '10%' },
        ]
    },
    {
        name: 'Knight',
        scene: '農場',
        desc: [
            { title: '生產加速', value: '30%' },
            { title: '消耗降低', value: '10%' },
            { title: '衰老延遲', value: '20%' },
        ]
    },
    {
        name: 'King',
        scene: '礦場',
        desc: [
            { title: '生產加速', value: '45%' },
            { title: '消耗降低', value: '30%' },
            { title: '衰老延遲', value: '15%' },
        ]
    },
    {
        name: 'Dragon',
        scene: '農場',
        desc: [
            { title: '生產加速', value: '50%' },
            { title: '消耗降低', value: '50%' },
            { title: '衰老延遲', value: '25%' },
        ]
    },
    {
        name: 'Angel',
        scene: '全部地點',
        desc: [
            { title: '生產加速', value: '100%' },
            { title: '消耗降低', value: '100%' },
            { title: '衰老延遲', value: '50%' },
        ]
    },
]
const convertMapCN = [
    {}, {},
    {
        name: 'Grunt',
        scene: '伐木場',
        desc: [
            { title: '生產加速', value: '10%' },
            { title: '消耗降低', value: '20%' },
            { title: '衰老延遲', value: '10%' },
        ]
    },
    {
        name: 'Shaman',
        scene: '伐木場',
        desc: [
            { title: '生產加速', value: '20%' },
            { title: '消耗降低', value: '30%' },
            { title: '衰老延遲', value: '20%' },
        ]
    },
    {
        name: 'Tauren',
        scene: '伐木場',
        desc: [
            { title: '生產加速', value: '35%' },
            { title: '消耗降低', value: '10%' },
            { title: '衰老延遲', value: '15%' },
        ]
    },
    {
        name: 'Chief',
        scene: '礦場',
        desc: [
            { title: '生產加速', value: '35%' },
            { title: '消耗降低', value: '40%' },
            { title: '衰老延遲', value: '20%' },
        ]
    },
    {
        name: 'Warrior',
        scene: '農場',
        desc: [
            { title: '生產加速', value: '10%' },
            { title: '消耗降低', value: '20%' },
            { title: '衰老延遲', value: '10%' },
        ]
    },
    {
        name: 'Priest',
        scene: '礦場',
        desc: [
            { title: '生產加速', value: '30%' },
            { title: '消耗降低', value: '30%' },
            { title: '衰老延遲', value: '10%' },
        ]
    },
    {
        name: 'Knight',
        scene: '農場',
        desc: [
            { title: '生產加速', value: '30%' },
            { title: '消耗降低', value: '10%' },
            { title: '衰老延遲', value: '20%' },
        ]
    },
    {
        name: 'King',
        scene: '礦場',
        desc: [
            { title: '生產加速', value: '45%' },
            { title: '消耗降低', value: '30%' },
            { title: '衰老延遲', value: '15%' },
        ]
    },
    {
        name: 'Dragon',
        scene: '農場',
        desc: [
            { title: '生產加速', value: '50%' },
            { title: '消耗降低', value: '50%' },
            { title: '衰老延遲', value: '25%' },
        ]
    },
    {
        name: 'Angel',
        scene: '全部地點',
        desc: [
            { title: '生產加速', value: '100%' },
            { title: '消耗降低', value: '100%' },
            { title: '衰老延遲', value: '50%' },
        ]
    },
]
let convertMap = computed(() => {
    if (lang.value =='en') {
        return convertMapEN;
    }
    else {
        return convertMapCN;
    }
});
//確認兌換。r為查詢用戶財產的數據
/* 
/* 
 misc.txt 中新增一個協議
// 將遊戲卡牌兌換成NFT
/misc_api/exchange_item_Cards/:game_id/:item_id/:level
方法:              PUT
參數:              :game_id 遊戲id
                  :item_id 物件id 
                  :level 卡牌等級          
                  
返回:              {
                        code:錯誤代碼,
                        msg:錯誤消息
                   } 

*/
const getMybackpack = inject('getMybackpack');
const confirmConvert = (r) => {
    let sendUrl = common.userApi.requestUrl + `/misc_api/exchange_item_cards/${props.modelValue.game_id}/${props.modelValue.item_id}/${props.modelValue.level}`;
    let sendData = common.buildSendData({});
    const sendHeader = common.buildHeaders('form');
    axios.put(sendUrl, sendData, { headers: sendHeader })
        .then((r) => {
            if (r.data.code == 0) {
                toastr['success'](r.data.msg);
                emits('update:modelValue', null);
                //轉換後，要調用祖父組件的方法，刷新數據
                getMybackpack();
            }
            else {
                console.error(r.data.msg);
                toastr['error'](r.data.msg);
            }
        });
    
}
</script>
<style lang="scss" scoped>
.back{
    margin-top: .2rem;
    margin-left: .3rem;
}
.content{
    min-height: 10rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    .arrow{
        margin: 0.5rem;
        .charge{
            margin-top: 1rem;
            display: flex;
            align-items: center;
            font-size: 0.18rem;
        }
    }
    .to{
        font-size: 0.16rem;
        margin-top: 1.4rem;
        margin-bottom: 0.55rem;
        width: 3.24rem;
        padding-bottom: 0.55rem;
        background: #4c5165;
        background-size: 100% auto;
        border-radius: 0.5rem;
        overflow: hidden;
        position: relative;
        .scene{
            position: absolute;
            width: 100%;
            bottom: 0.2rem;
            left: 0;
            text-align: center;
            font-weight: bold;
            color: #c7c7c7;
            span{
                color: #79e3fb;
                margin-left: 0.2rem;
            }
        }
        .img{
            background-color: #8b8d9f;
        }
        img{
            width: 100%;
        }
        .name{
            height: 0.42rem;
            line-height: 0.42rem;
            font-size: 0.24rem;
            font-weight: bold;
            color: #4c4c60;
            text-align: center;
            margin: 0;
            background-color: #c8c8dc;
        }
        .level{
            margin: 0.2rem 0;
        }
        .price{
            margin-bottom: 0.4rem;
            font-size: 0.20rem;

        }
        .desc {
            background: #4c5165;
            li {
                height: .3rem;
                line-height: 0.3rem;
                font-size: 0.16rem;
                display: flex;
                justify-content: space-between;
                border: 0.03rem solid #fff;
                border-width: 0 0.03rem;
                margin: 0.02rem;
                padding: 0 0.05rem;
            }
        }
    }
        .btn{
            display: flex;
            justify-content: center;
        }
}
</style>