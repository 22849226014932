<template>
  <div class="backpack" v-if="myUid">
    <div class="class">
      <ul>
        <li :class="{ on: classType == 0, mortina: true }" @click="classType = 0">
          <span>{{ lang == 'en' ? 'PROPS' :'道具'}}</span>
        </li>
        <li :class="{ on: classType == 1, mortina: true }" @click="classType = 1">
          <span>{{ lang == 'en' ? 'CONVERT' :'轉換'}}</span>
        </li>
      </ul>
    </div>
    <div v-if="(myBuyGoodsList && myBuyGoodsList.length > 0) || myItemCardsList || (myNFTList && myNFTList.length>0) || (myHoesList && myHoesList.length>0)">
      <div class="props" v-if="classType == 0">
        <div class="list" v-if="!currentItem">
          <ui_select :list="typeList" v-model:val="showType" class="onlypc"></ui_select>
          <ul>

            <template v-for="(item, index) in myItemCardsList" :key="index">
              <Item class="cards" :imgsrc="item.logo" :price="item.price" @click="setCurrentItem(item,'Card')" v-if="item.nft_exchange_status != 2 && item.durability>0" v-show="showType == 0 || showType==2">
                <template #data>
                  <dl>
                    <dd><span class="lable">{{item.name[lang]}}</span><span class="value">
                      <Star_level class="level" v-model="item.level" v-if="item.level" />
                    </span></dd>
                    <dd><span class="lable">{{ lang == 'en' ? "Durability":"耐久度"}}</span><span class="value">{{item.durability}} / {{ item.max_durability }}</span></dd>
                  </dl>
                </template>
              </Item>
            </template>

            <template v-for="(item, index) in myHoesList" :key="index">
              <Item class="hoes" :imgsrc="item.info.logo" :name="item.info.name||'-'" v-show="showType == 0 || showType==3"  @click="setCurrentItem(item,'HOES')">
                <template #data>
                  <dl>
                    <!-- <dd><span class="lable"></span><span class="value">{{ item.info.name }}</span></dd> -->
                    <dd><span class="lable">{{lang=='en'?'Value':'使用獎勵'}}</span><span class="value" v-html="formatAssetInfos(item.info.award)"></span></dd>
                    <dd><span class="lable">{{lang=='en'?'Deadline':'有效期'}}</span><span class="value">{{item.info.life / (24*60*60)}} {{lang=='en'?'Day':'天'}}</span></dd>
                    <dd><span class="lable">{{lang=='en'?'Today used count':'今日使用次數'}}</span><span class="value">{{item.today_used_count}}</span></dd>
                    <dd><span class="lable">{{lang=='en'?'Total used count':'共計使用次數'}}</span><span class="value">{{item.total_used_count}}</span></dd>
                  </dl>
                </template>
              </Item>
            </template>

            <template v-for="(item, index) in myNFTList" :key="index">
              <Item class="nft" :imgsrc="item.logo_url" :name="item.name||'-'" :level="item.nft_level" @click="setCurrentItem(item,'NFT')" v-show="showType == 0 || showType==1">
                <template #data>
                  <dl>
                    <dd><span class="lable">{{lang=='en'?'Name':'名稱'}}</span><span class="value">{{item.name}}</span></dd>
                    <dd><span class="lable">{{lang=='en'?'Level':'品質'}}</span><span class="value">
                      <Star_level class="level" v-model="item.nft_level" v-if="item.nft_level" />
                    </span></dd>
                    <dd></dd>
                    <dd></dd>
                  </dl>
                </template>
              </Item>
            </template>

            <template v-for="(item, index) in myBuyGoodsList" :key="index">
              <Item class="normal" v-if="item.status==0" :imgsrc="item.logo_url" :name="item.name||'-'" v-show="showType == 0 || showType==3"  @click="setCurrentItem(item,'goods')">
                <template #data>
                  <dl>
                    <dd><span class="lable">{{lang=='en'?'Name':'名稱'}}</span><span class="value">{{ item.name }}</span></dd>
                    <dd></dd>
                    <dd></dd>
                    <dd></dd>
                  </dl>
                </template>
              </Item>
            </template>
          </ul>
        </div>
        <Sell_Use_detail v-else v-model="currentItem" />
      </div>
      <div class="convert" v-else>
        <Convert v-model="myItemCardsList" />
      </div>
    </div>
    <p class="null" v-else>{{ lang == 'en' ? 'You dont have an props' :'暫無數據'}}</p>
  </div>
</template>

<script src="./backpack.js"></script>

<style lang='scss' scoped src="./mall.scss"></style>
<style lang="scss" scoped>
  .list{
    ul{
      li{
        cursor: pointer;
      }
    }
  }
  .backpack .class{
    padding-bottom: 0.14rem;
  }
</style>