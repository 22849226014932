<template>
    <div class="templage convert">
        <p v-if="props.modelValue.length == 0"></p>
        <ul v-else-if="!currentItem && props.modelValue.length>0">
            <template v-for="(item, index) in props.modelValue" :key="index">
                <ConvertItem :item="item" v-if="item.nft_exchange_status<2">
                    <div class="btn">
                        <ui_btn_2 v-if="item.nft_exchange_status == 0" :redback="true" :width="'1.54rem'" :height="'0.4rem'"
                            @click="currentItem = item">{{ lang == 'en' ? 'CONVERT' :'轉換' }}</ui_btn_2>
                        <div v-if="item.nft_exchange_status == 1">{{ lang == 'en' ? 'EXCHANGING...' :'轉換中...' }}</div>
                        <!-- <div v-if="item.nft_exchange_status == 2">{{ lang == 'en' ? 'converted' :'已轉換' }}</div> -->
                    </div>
                </ConvertItem>
            </template>
        </ul>
        <Convert_detail v-else v-model="currentItem" />
    </div>
</template>
<script setup>
import { computed,ref } from 'vue';
import { useStore } from 'vuex';
import Item from "./Item.vue";
import ui_btn_2 from '@/components/ui/ui_btn_2.vue';
import Convert_detail from '@/components/mall/Convert_detail.vue';
import ConvertItem from '@/components/mall/ConvertItem.vue';

let store = useStore();
let lang = computed(() => {
    return store.state.lang;
})
let props = defineProps(['modelValue']);
console.log(props.modelValue)


// 当前选择对象
let currentItem = ref();
</script>
<style lang="scss" scoped>
.convert{
    font-size: 0.16rem;
    ul{
        display: flex;
        justify-content: center;
    }
    .btn{
        margin-top: 0.3rem;
        display: flex;
        justify-content: center;
    }
    :deep(.convert_item){
        cursor: default;
    }
}
</style>
