<template>
  <div class="mall">
    <div class="layout_left_right">
      <div class="layout">
        <div class="layout_left">
          <ui_side_menu @updateIndex="switchItem" :sideMenuList="lang=='en'?['MALL', 'MY BACKPACK']:['商城', '我的背包']"
            :activeIndex="0"></ui_side_menu>
        </div>
        <div class="layout_right">
          <mall v-if="activeIndex == 0"></mall>
          <backpack v-if="activeIndex == 1"></backpack>
        </div>
      </div>
    </div>
  </div>
  <pagebottom></pagebottom>
</template>
<script>

import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch
} from "vue";
import {
  useStore
} from "vuex";
import ui_side_menu from "@/components/ui/ui_side_menu";
import mall from "@/components/mall/mall.vue";
import backpack from "@/components/mall/backpack.vue";
import pagebottom from "@/components/pagebottom/pagebottom.vue";
import common from "../utils/common";
import axios from "axios";

export default defineComponent({
  name: "exchange",
  components: {
    ui_side_menu,
    mall,
    backpack,
    pagebottom,
  },
  setup() {
    let store = useStore();
    let lang = computed(() => {
      return store.state.lang
    });

    let leftList = ["MALL", "MY BACKPACK"];
    let activeIndex = ref(0);
    const switchItem = (inx) => {
      activeIndex.value = inx;
      console.log('--lb', activeIndex.value);
    };
    let uid = computed(() => {
      return store.state.uid;
    });

    //監控uid。如果沒有uid，顯示登錄框
    // if (!uid.value && !common.userApi.token) {
    //   common.showLogin();
    // } else {
    // common.queryFinance();
    // }
    // watch(uid, (newValue, oldValue) => {
    //   console.log('--lb',"uid changed", newValue, oldValue);
    //   if (!uid.value && !common.userApi.token) {
    //     common.showLogin();
    //   } else {
    //     common.queryFinance();
    //   }
    // });

    onMounted(() => { });
    return {
      leftList,
      activeIndex,
      uid,
      switchItem, lang
    };
  },
});
</script>
<style scoped lang="scss">
.mall {
  .layout_left_right {
    min-height: calc(100vh - 0.75rem - 5.8rem);
    height: auto;

    .layout {
      .layout_left {
        min-height: calc(100vh - 0.75rem - 5.8rem);
        height: auto;
      }

      .layout_right {
        padding: 0;
      }
    }
  }
}
</style>
<style lang="scss">
@media screen and (max-width: 1059px){
  .router_mall .mall{
    .layout_left_right .layout{
      display: block;
      background-color: #26254C;
      .layout_left{
        width: 100%;
        height: .96rem;
        min-height: .96rem;
        border: 0;
        .ui_slide_menu{
          display: flex;
          flex-wrap: nowrap;
          align-items: top;
          background-color: #1B203F;
          .icon_back{
            display: inline-block;
            width: .48rem;
            height: .48rem;
            flex-shrink: 0;
            flex-grow: 0;
            margin: .2rem;
          }
          ul{
            display: flex;
            padding: 0;
            flex-direction: row;
            li{
              border: 0;
              span{
                font-size: .32rem;
                opacity: 1;
                &.active{
                  color: #EF4F55;
                    &::after{
                      width: 0.52rem;
                      height: .08rem;
                      top: auto;
                      bottom: 0;
                      left: 50%;
                      margin-left: -0.26rem;
                    }
                }
              }
            }
          }
        }
      }
      .layout_right{
        .class{
          border-bottom: 0;
          padding: 0;
          height: 1.4rem;
          display: flex;
          align-items: center;
          margin-left: .32rem;
          ul{
            white-space: nowrap;
            li{
              display: inline-block;
              width: auto;
              margin: 0 .08rem;
              background-color: transparent;
              &.active,&.on{
                background-color: #EF4F55;;
              }
              span{
                width: auto;
                min-width: 1.4rem;
                padding: 0 0.1rem;
                margin: 0;
                text-align: center;
                font-size: .32rem;
                font-family: "PingFangSCRegular", Arial, "Helvetica Neue", Helvetica, sans-serif;
              }
            }
          }
        }
        .list{
          padding: 0 .48rem;
          ul{
            display: flex;
            justify-content: space-between;
            li{
              width: 3.1rem;
              margin: 0;
              margin-bottom: .24rem;
            }
          }
        }
      }
    }
    
  }
}
</style>