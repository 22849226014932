<template>
  <li class="templage convert">
    <div :class="item.item_id > 5 ? 'box human' : 'box orc'">
      <img :src="item.logo" alt="" />
      <div class="name">{{ item.name[lang] || item.name }}</div>
      <div class="level" v-if="item.item_id > 5">
        <img
          :src="cdnUrl + '/web_assets/img/convert/Star_Blue.png'"
          alt=""
          v-for="(ii, index) in item.level"
          :key="index"
        />
      </div>
      <div class="level" v-else>
        <img
          :src="cdnUrl + '/web_assets/img/convert/Star_Red.png'"
          alt=""
          v-for="(ii, index) in item.level"
          :key="index"
        />
      </div>
      <ul class="desc">
        <li>
          <span class="label"
            >{{ lang == "en" ? "Earning Bonus" : "收入加成" }}:&emsp;</span
          ><span>{{ item.earning_ratio / 100 }}%</span>
        </li>
        <li>
          <span class="label">{{ lang == "en" ? "Durability" : "耐久度" }}:&emsp;</span
          ><span>{{ item.durability }} / {{ item.max_durability }}</span>
        </li>
      </ul>
    </div>
    <slot></slot>
  </li>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

let store = useStore();
let cdnUrl = computed(() => {
  return store.state.cdnUrl;
});
let lang = computed(() => {
  return store.state.lang;
});
defineProps(["item"]);
</script>
<style lang="scss" scoped>
li {
  list-style: none;
  .box {
    margin: 0.55rem;
    width: 2.8rem;
    padding: 0 0.66rem;
    height: 6.54rem;
    background: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.human {
      background-image: url("//cdn.blockwar.io/web_assets/img/convert/Card_Blue.png");
    }

    &.orc {
      background-image: url("//cdn.blockwar.io/web_assets/img/convert/Card_Red.png");
    }

    img {
      width: 2.8rem;
      height: auto;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .level {
      margin: 0.23rem 0;
      height: 0.5rem;

      img {
        width: 0.48rem;
        height: auto;
      }
    }

    .name {
      font-size: 0.24rem;
      font-weight: bold;
      height: 0.6rem;
      line-height: 0.6rem;
      color: #4c4c60;
    }

    .desc {
      padding-bottom: 0.3rem;
      font-size: 0.16rem;
      line-height: 0.3rem;
      li {
        padding: 0 0.2rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
