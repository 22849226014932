<template>
  <div class="mall_right">
    <div class="class">
      <ui_select class="onlypc" :list="typeList" v-model:val="tabIndex"></ui_select>
      <ul class="onlymobile">
        <li v-for="(item, index) in typeList" :key="index" :class="{'mortian':true,'active':tabIndex==index-1}" @click="tabIndex=index-1"><span>{{ item.name }}</span></li>
      </ul>
    </div>
    <div class="list" v-if="!currentItem">
      <ul>
        <Item v-show="tabIndex==1 || tabIndex==-1" v-if="cardsList" v-for="(item, index) in cardsList" @click="setCurrentItem(item,1)" :key="index" :imgsrc="formatImgsrc(1, item)" :price="formPrice(1, item)">
          <Btn_buy class='btnbox' :data="item" :type="1"></Btn_buy>
        </Item>
        
        <Item v-show="tabIndex==1 || tabIndex==-1" v-if="silverHoeList" v-for="(item, index) in silverHoeList" @click="setCurrentItem(item,1)" :key="index" :imgsrc="item.logo" :award="item.award" :price="formPrice(1, item)">
          <template #data>
            <dl>
              <dd><span class="lable">{{lang=='en'?'Value':'使用獎勵'}}</span><span class="value"><i class="ico_asset ico_silver"></i>{{item.award[0].value}}</span></dd>
              <dd><span class="lable">{{lang=='en'?'Life':'有效期'}}</span><span class="value">{{item.life / (24*60*60)}} {{lang=='en'?'Day':'天'}}</span></dd>
            </dl>
          </template>
          <Btn_buy class='btnbox' :data="item" :type="1"></Btn_buy>
        </Item>

        <Item v-show="tabIndex==0 || tabIndex==-1" v-if="ntfList" v-for="(item, index) in ntfList" :key="index" @click="setCurrentItem(item,0)" :imgsrc="formatImgsrc(0,item)"
          :price="formPrice(0,item)" :charge="item.charge">
          <template #data>
            <dl>
              <dd><span class="lable">{{lang=='en'?'Durability':'耐久度'}}</span><span class="value">{{item.durability}}</span></dd>
              <dd><span class="lable">{{lang=='en'?'Charge':'手續費'}}</span><span class="value"><i class="ico_asset ico_gold"></i>{{item.charge}} </span></dd>
            </dl>
          </template>
          <Btn_buy class='btnbox' :data="item" :type="0"></Btn_buy>
        </Item>

        <Item v-show="(tabIndex==2 || tabIndex==-1) && item.count>0" :class="{ out: item.count == 0 }" :data-count="item.coun" v-if="goodsList" v-for="(item, index) in goodsList" :key="index" @click="setCurrentItem(item,2)"
          :imgsrc="formatImgsrc(2,item)"
          :price="formPrice(2, item)" :out="item.count == 0">
          <Btn_buy class='btnbox' :data="item" :type="2"></Btn_buy>
        </Item>
      </ul>
    </div>
    <Detail v-else class="detail" v-model="currentItem" :name="formatItemName(currentItem.setTab, currentItem)" :imgsrc="formatImgsrc(currentItem.setTab, currentItem)" :price="formPrice(currentItem.setTab, currentItem)" :tabIndex="currentItem.setTab"></Detail>
  </div>
</template>

<script src="./mall.js"></script>

<style lang='scss' scoped src="./mall.scss">

</style>