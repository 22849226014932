<template>
  <div class="ui_slide_menu">
    <iconback @click="toCenter" class="back onlymobile" />
    <ul>
      <li
        v-for="(item, inx) in sideMenuList"
        :key="inx"
        class="mortina"
        @click="changeIndex(inx)"
      >
        <span :class="{ active: activeIndex == inx }">{{ item }}</span>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
ul {
  padding-top: 0.15rem;
  li {
    cursor: pointer;
    border-bottom: 1px solid #38366c;
    span {
      color: #fff;
      opacity: 0.5;
      font-size: 0.2rem;
      display: inline-block;
      padding: 0.2rem 0.4rem;
      &.active {
        opacity: 1;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0.05rem;
          height: 100%;
          background: linear-gradient(83.73deg, #ff8688 0%, #e9373e 99.75%);
        }
      }
    }
  }
}
</style>
<script>
import { defineComponent, ref } from "vue";
// import { useStore } from "vuex";
import { useRouter } from "vue-router";
import iconback from "./icon/icon_back.vue";

export default defineComponent({
  name: "",
  props: {
    sideMenuList: {
      default() {
        return ["menu 1", "menu 2", "menu 3", "menu 4"];
      },
    },
    activeIndex: { default: 0 },
  },
  components: { iconback },
  emits: ["updateIndex"],
  setup(props, ctx) {
    let router = useRouter();
    const toCenter = () => {
      router.push("/center");
    };
    //   let store = useStore();
    const changeIndex = (inx) => {
      ctx.emit("updateIndex", inx);
    };
    return { changeIndex, toCenter };
  },
});
</script>
