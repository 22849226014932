import { defineComponent, reactive, ref, onMounted, computed, provide } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import common from "../../utils/common";
import ui_btn_1 from '@/components/ui/ui_btn_1'
import ui_select from "@/components/ui/ui_select";
import ui_calendar from "@/components/ui/ui_calendar";
import Item from "./Item.vue";
import Sell_Use_detail from './Sell_Use_detail.vue';
import Convert from './Convert.vue';
import axios from "axios";
import Star_level from '@/components/ui/Star_level.vue';
export default defineComponent({
    name: "backpack",
    props: {},
    components: { ui_btn_1, ui_select, ui_calendar, Item, Sell_Use_detail, Convert,Star_level },
    setup(props, ctx) {
        let router = useRouter();
        let store = useStore();

        let lang = computed(() => {
            return store.state.lang;
        });

        let myUid = computed(() => {
            return store.state.uid;
        });
        if (!myUid.value) {
            console.log('--lb', 1111)
            common.showLogin();
        }

        // 揹包商品的類型
        let typeList = [
            {
                name: "ALL",
                val: 0,
                default: true,
            },
            {
                name: "Shed",
                val: 1,
                default: false,
            },
            {
                name: "Card",
                val: 2,
                default: false,
            },
            {
                name: "Other",
                val: 3,
                default: false,
            },
        ];
        let showType = ref(0);

        let classType = ref(0);// 0:props,1:convert

        //

        //選中的項目
        const currentItem = ref(null);
        const setCurrentItem = (d,itemType) => {
            currentItem.value = d;
            currentItem.value.itemType = itemType;
            console.log(d)
        }

        let userInfo = computed(() => {
            return store.state.userInfo;
        });
        //獲取我的揹包
        let myBuyGoodsList = ref();
        let myItemCardsList = ref();
        let myNFTList = ref();
        let myHoesList = ref();
        // 我的商品
        const getMyGoods = () => {
            if (!userInfo.value || !userInfo.value.uid || !userInfo.value.token) {
                return;
            }
            const sendUrl = common.userApi.requestUrl + "/finance_api/user_goodses";
            const sendData = { headers: common.buildHeaders('form') };
            return axios.get(sendUrl, sendData);
        };
        // 我的卡片
        const getMyItemCards = () => {
            if (!userInfo.value || !userInfo.value.uid || !userInfo.value.token) {
                return;
            }
            const sendUrl = common.userApi.requestUrl + "/misc_api/item_cards/0";
            const sendData = { headers: common.buildHeaders('form') };
            return axios.get(sendUrl, sendData);
        };
        // 我的NFT
        const getMyNFTprops = () => {
            if (!userInfo.value || !userInfo.value.uid || !userInfo.value.token) {
                return;
            }
            const sendUrl = common.userApi.requestUrl + "/finance_api/block_chain/2/nft/prop/orders?status=1";
            const sendData = { headers: common.buildHeaders('form') };
            return axios.get(sendUrl, sendData);
        };
        // 我的鋤頭

        const getMyHoes = ()=>{
            const sendUrl = common.userApi.requestUrl+'/finance_api/silver_hoes';
            return axios.get(sendUrl);
        }

        let NFTConfig; //NFT的定義。含名稱圖片等
        const getMybackpack = function () { 
            if (!userInfo.value || !userInfo.value.uid || !userInfo.value.token) {
                return;
            }
            getNTFs().then(() => {
                axios.all([getMyGoods(), getMyItemCards(), getMyNFTprops(),getMyHoes()])
                .then(axios.spread(function (res1, res2,res3,res4) {
                    if (res1.data.infos){
                        myBuyGoodsList.value = res1.data.infos;
                    }
                    if (res2.data.infos){
                        myItemCardsList.value = res2.data.infos;
                        // 暫時手動 遊戲卡牌名稱和頭像
                        const tempName = [
                            { 'en': 'Orc Horde', 'zh_cn': '獸族 部落'},
                            { 'en': 'Human Alliance', 'zh_cn':'人族 聯盟'},
                            { 'en': 'Grunt', 'zh_cn':'獸人戰士', 'logo':'//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Orcs.png'},
                            { 'en': 'Shaman', 'zh_cn': '薩滿', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Shaman.png' },
                            { 'en': 'Tauren', 'zh_cn': '牛頭人', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Minotaur.png' },
                            { 'en': 'Chief', 'zh_cn': '酋長', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Sheik.png' },
                            { 'en': 'Warrior', 'zh_cn': '人類戰士', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Warrior.png' },
                            { 'en': 'Priest', 'zh_cn': '牧師', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Mage.png' },
                            { 'en': 'Knight', 'zh_cn': '騎士', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Knight.png' },
                            { 'en': 'King', 'zh_cn': '國王', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_King.png' },
                            { 'en': 'Dragon', 'zh_cn': '龍', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Dragon.png' },
                            { 'en': 'Angel', 'zh_cn': '天使', 'logo': '//cdn.blockwar.io/web_assets/img/CardTitle/BackPack_Angel.png' },
                            // { 'en': 'War_Box', 'zh_cn': '寶箱', 'logo': '' },
                        ]
                        for (let i = 0; i < myItemCardsList.value.length; i++){
                            if (!myItemCardsList.value[i].name && !myItemCardsList.value[i].logo) {
                                myItemCardsList.value[i].name = tempName[myItemCardsList.value[i].item_id];
                                myItemCardsList.value[i].logo = tempName[myItemCardsList.value[i].item_id].logo;
                            }
                        }

                    }
                    if (res3.data.infos){
                        myNFTList.value = res3.data.infos;
                        for (let i = 0; i < myNFTList.value.length; i++){
                            myNFTList.value[i].price = common.fortmatNFTprice(myNFTList.value[i]);
                            for (let j = 0; j < NFTConfig.length; j++){
                                if (NFTConfig[j].nft_category == myNFTList.value[i].nft_category && NFTConfig[j].nft_level == myNFTList.value[i].nft_level) {
                                    myNFTList.value[i].name = NFTConfig[j].name;
                                    myNFTList.value[i].logo_url = NFTConfig[j].logo_url;
                                }
                            }
                        }
                    }
                    if(res4.data.Infos){
                        const sendUrl = common.userApi.requestUrl + '/misc_api/sys_config/items?&names=silver_hoe_infos';
                        axios.get(sendUrl).then((res)=>{
                            if(res.data.code==0){
                                const silver_hoe_infos = JSON.parse(res.data.items[0].value).infos;
                                for(let i=0;i<res4.data.Infos.length;i++){
                                    for(let j=0;j<silver_hoe_infos.length;j++){
                                        if(res4.data.Infos[i].hlevel==silver_hoe_infos[j].level){
                                            res4.data.Infos[i].info = silver_hoe_infos[j];
                                            break;
                                        }
                                    }
                                }
                                myHoesList.value = res4.data.Infos;
                            }

                        });
                        // for()
                    }
                    // console.log('getMyGoods', myBuyGoodsList.value);
                    // console.log('getMyItemCards', myItemCardsList.value);
                    // console.log('myNFTList', myNFTList.value);
                }))
            });
        }
        provide('getMybackpack', getMybackpack);
        //獲取系統默認ntf道具
        const getNTFs = async () => {
            const sendUrl = common.userApi.requestUrl + '/misc_api/sys_config/items?&names=nft_prop_infos';
            const sendHeader = common.buildHeaders('form');
            common.showLoading("backpack getNTFs");
            await axios.get(sendUrl, { headers: sendHeader }).then((r) => {
                if (r.data.code == 0) {
                    const v = JSON.parse(r.data.items[0].value);
                    NFTConfig = v.infos;
                    return v;
                }
                else {
                    console.error(r.data.msg);
                    toastr['error'](r.data.msg);
                    return null;
                }
            }).finally(() => {
                common.hideLoading("backpack getNTFs");
            });
        };

        const formatAssetInfos = (v)=>{
            return common.formatAssetInfos(v);
        }
        onMounted(() => {
            getMybackpack();
        });

        return { lang,myNFTList,lang,myBuyGoodsList, myHoesList,myItemCardsList,myUid, currentItem, setCurrentItem, typeList, showType, classType,formatAssetInfos };
    },
});