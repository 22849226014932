<template>
    <li class="template item">
        <div class="img">
            <img :src="props.imgsrc" alt="" />
        </div>
        <div class="box">
            <div class="slot">
                <slot name="data"></slot>
            </div>
            <div v-if="props.price" class="v">
                <div class="li" v-html="formatAssetInfos(props.price)"></div>
            </div>
            <div class="out" v-if="props.out">OUT OF STOCK</div>
            <slot></slot>
            <!-- <Btn_buy class='btnbox' v-model="props.data" :type="props.type"></Btn_buy> -->
        </div>
    </li>
</template>
<script setup>
import common from '../../utils/common';
import Star_level from '@/components/ui/Star_level.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import Btn_buy from './Btn_buy.vue';

let store = useStore();
let lang = computed(() => { 
    return store.state.lang;
});

let props = defineProps(['imgsrc', 'name', 'price', 'out', 'level','charge','award']);
const formatAssetInfos = (asset_infos) => {
    return common.formatAssetInfos(asset_infos);
}
</script>
<style lang="scss" scoped>
li {
    width: 2.5rem;
    height: 3.82rem;
    overflow: hidden;
    border-radius: 0.1rem;
    background-color: #DCE6FF;
    text-align: center;
    font-size: 0.2rem;
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0;
    margin-right: 0.25rem;
    margin-bottom: 0.3rem;
    position: relative;
    padding: 0;
    box-sizing: content-box;
    .img {
        background-color: #DCE6FF;
        text-align: center;
        width: 100%;
        min-height: 2.5rem;
        position: relative;
        border-radius: 0.1rem 0.1rem 0 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 1;
        img {
            width: 100%;
            height: auto;
            flex-grow: 0;
            flex-shrink: 1;
        }
    }
    .box{
        background-color: #DCE6FF;
        position: absolute;
        width: 100%;
        color: #4F4F4F;
        left: 0;
        bottom: 0;
        .btnbox{
            display:none;
        }
    }

    &:hover {        
        .btnbox{
            display: block;
        }
    }

    &.out {
        opacity: 0.5;
    }

    dd,
    dl {
        margin: 0;
        font-size: 0.14rem;
    }

    
    p {
        margin: 0;
        line-height: 0.42rem;
        display: flex;
        justify-content: center;
    }

    .v {
        padding: 0.08rem;
        overflow: hidden;
        .li{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 0.1rem;
            min-height: 0.5rem;
        }
        :deep(span.assets_info){
            display: inline-block;
            flex-shrink: 0;
            color: #000;
            font-size: 0.24rem;
            .ico_asset{
                width: 0.2rem;
                height: 0.2rem;
                background-size: contain;
            }
        }
        img {
            width: 0.2rem;
            height: auto;
            margin-right: 0.05rem;
        }
    }

    .out {
        position: absolute;
        font-size: 0.12rem;
        top: 0;
        right: 0;
        opacity: 0.5;
        padding: 0.05rem;
        background-color: #EF4F55;
    }
    .charge{
        padding: 0.1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>